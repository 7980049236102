<template>
    <div class="body-container">
        <BannerSection title="Préstamos con mal historial<br class='d-none d-lg-block'/> crediticio: Ibancar te ayuda"
                       subtitle="Obtén financiamiento sin complicaciones, incluso con historial crediticio negativo. Conoce nuestros préstamos con mal buró" />
        <PerksComponent></PerksComponent>
        <div class="section-container regular-text">
            <div class="position-relative  pb-3 pb-md-0 ">
                <div class="text-container">
                    <span class="bold-text">
                        <h3 style="font-size: 20px;">¿Qué es el buró de crédito?</h3>
                        

                    </span>
                    <p>Es una entidad que recopila y resume tu historial financiero en una calificación crediticia. Los
                        bancos y prestamistas utilizan este score para decidir si te ofrecen un préstamo y en qué
                        condiciones.</p>
                    <br>
                    <ul>
                        <li>
                            <p class="mb-0 bold-text"
                               style="font-size: 20px;">
                                Score alto:
                            </p>
                            <span> acceso a mejores tasas y condiciones de préstamo.</span>
                        </li>

                        <br>
                        <li>
                            <p class="mb-0 bold-text"
                               style="font-size: 20px;">
                                Score bajo
                            </p>
                            <span>podrías enfrentar tasas más altas y términos de préstamo restrictivos.</span>
                        </li>

                        <br>
                    </ul>
                    <h3 style="font-size: 20px;">¿Tienes un score bajo? No te preocupes, en Ibancar analizamos más allá
                        del buró de crédito para
                        ofrecerte soluciones de financiamiento que se adapten a tu situación.</h3>
                    
                </div>
            </div>
        </div>

        <div class="section-container regular-text">
            <div class="position-relative  pb-3 pb-md-0 ">
                <div class="text-container">
                    <span class="bold-text">
                        <h3 style="font-size: 20px;">Ibancar: tu aliado incluso con mal historial crediticio
                        </h3>
                        
                    </span>
                    <p>En Ibancar, entendemos que un historial crediticio no siempre refleja tu situación actual. Por
                        eso, los préstamos con mal buró son soluciones diseñadas especialmente para quienes enfrentan
                        dificultades con sus finanzas. </p>
                    <br>
                    <span class="bold-text">
                        <p style="font-size: 20px;">Ofrecemos:
                        </p>
                        <br>
                    </span>
                    <ul>
                        <li>
                            <h4 class="mb-0 bold-text"
                                style="font-size: 20px;">
                                Proceso de aprobación flexible:
                            </h4>
                            <span>no dejamos que un número defina tu elegibilidad. Nuestro proceso considera múltiples
                                factores para darte la mejor oportunidad de obtener un préstamo.</span>
                        </li>

                        <br>
                        <li>
                            <h4 class="mb-0 bold-text"
                                style="font-size: 20px;">
                                Análisis individualizado:
                            </h4>
                            <span>cada solicitud es única. Analizamos tu caso específico para entender tus necesidades y
                                posibilidades, asegurando una oferta que realmente se adapte a ti.</span>
                        </li>
                        <br>
                    </ul>
                    <h3 style="font-size: 20px;">Con Ibancar, no estás solo. Te acompañamos en cada paso para ofrecerte
                        una oportunidad para mejorar tu salud financiera.</h3>
                    
                </div>
            </div>
        </div>

        <div style="background-color: rgba(255, 165, 0, 0.04); padding: 1.5rem 0rem; margin: 1.5rem 0rem;">
            <div class="ibancar-election">
                <p>¿Por qué elegir Ibancar?</p>
            </div>
            <BeneficiosIbancarCarousel />
        </div>

        <div class="section-container regular-text">
            <div class="position-relative  pb-3 pb-md-0 ">
                <div class="text-container">
                    <span class="bold-text">
                        <br>
                        <h3 style="font-size: 20px;">Ventajas de elegir Ibancar
                        </h3>
                        
                    </span>
                    <p>Cuando eliges Ibancar, estás optando por un servicio que entiende tus necesidades y se adapta a
                        tu situación</p>
                    <p>Estas son las principales ventajas que ofrecemos:</p>
                    <ul>
                        <li>
                            <p><b>Rapidez en la gestión:</b></p>
                            <span> todo el proceso se realiza online, desde la solicitud hasta la aprobación.
                                Garantizamos una respuesta rápida, generalmente en 24 horas.</span>
                        </li>

                        <br>
                        <li>
                            <p><b>Sin necesidad de buen historial crediticio:</b></p>
                            <span>no permitimos que la baja calificación crediticia sea un obstáculo. Aceptamos clientes
                                con malos historiales en buró de crédito, buscando soluciones donde otros ven
                                problemas.</span>
                        </li>
                        <br>

                        <li>
                            <p><b>Tu auto como garantía: </b></p>
                            <span>utiliza tu vehículo como garantía sin necesidad de dejar de usarlo. Este enfoque te
                                permite obtener el financiamiento que necesitas sin sacrificar tu movilidad.</span>
                        </li>
                    </ul>

                    <span class="bold-text">
                        <br>
                        <h3 style="font-size: 20px;">¿Cómo pedir un préstamo en Ibancar?
                        </h3>
                        
                    </span>
                    <ol>
                        <li>
                            <p><b>Completa tu solicitud en línea: </b></p>
                            <span> accede a nuestro formulario de solicitud en la web y rellénalo con la información
                                requerida. </span>
                        </li>

                        <br>
                        <li>
                            <p><b>Envía la documentación necesaria: </b></p>
                            <span> te solicitaremos algunos documentos para verificar tu información. Este proceso es
                                seguro y confidencial.</span>
                        </li>
                        <br>

                        <li>
                            <p><b>Recibe una respuesta rápida: </b></p>
                            <span> evaluaremos tu solicitud con rapidez. Si es aprobada, el dinero será depositado en tu
                                cuenta. Así de simple y rápido, sin esperas innecesarias.</span>
                        </li>
                        <br>
                    </ol>

                    <h3 class="bold-text"
                        style="font-size: 20px;">No dejes que el buró de crédito detenga tus planes </h3>
                    
                    <h4 style="font-size: 20px;">Nuestro objetivo es ayudarte a avanzar, sin importar los obstáculos. Si
                        estás listo para dar el siguiente paso y obtener el financiamiento que necesitas, actúa ahora.
                    </h4>

                    
                </div>
            </div>
        </div>

        <SolicitaloBannerS2 />
    </div>
</template>

<script>
import { useMeta } from 'vue-meta';
import BannerSection from '@/components/Landings/BannerSection.vue';
import BeneficiosIbancarCarousel from '@/components/PrestamoGarantiaAuto/BeneficiosIbancarCarousel.vue';
import SolicitaloBannerS2 from '@/components/Landings/SolicitaloBannerS2.vue';
import PerksComponent from '../components/PerksComponent.vue';


export default {
    name: 'PrestamosConMalHistorial',
    components: {
        BannerSection,
        BeneficiosIbancarCarousel,
        SolicitaloBannerS2,
        PerksComponent
    },
    setup() {
        useMeta(
            {
                title: 'Préstamos con mal buro | Ibancar',
                meta: [
                    {
                        name: 'robots',
                        content: 'index'
                    },
                    {
                        name: 'description',
                        content: 'Préstamos con mal buró sin complicaciones. Utiliza tu auto como aval para obtener el efectivo que necesitas, sin importar tu historial crediticio. ¡Solicita ahora!'
                    },
                    {
                        name: 'keywords',
                        content: 'prestamos con mal buro'
                    }
                ],
                link: [
                    // { href: 'https://ibancar.com/prestamos-con-aval-de-coche-sin-cambiar-de-titular', rel: 'alternate', hreflang: 'es-es' },
                    { href: 'https://ibancar.mx/prestamos-sin-buro', rel: 'alternate', hreflang: 'es-MX' }
                ]
            }
        )
    },

    data() {
        return {
            ventajas: [
                {
                    image: 'image12.svg',
                    title: 'Ejecutivo personalizado',
                },
                {
                    image: 'solicitud-en-linea.svg',
                    title: 'Proceso simple sin salir de casa',
                },

                {
                    image: 'prestamos-48-hrs.svg',
                    title: 'Hasta $120,000 en 24H',
                },
                {
                    image: 'proceso-simple.svg',
                    title: 'Aceptamos personas en Buró.',
                }

            ]
        }
    }
}
</script>

<style scoped lang="scss">
@import 'bootstrap/scss/_functions.scss';
@import 'bootstrap/scss/_variables.scss';
@import 'bootstrap/scss/_mixins.scss';


.image-container {
    text-align: center;
    margin: 3rem 0rem;

    img {
        max-height: 400px;
    }
}

.body-container {
    margin-top: 4.5rem;
    min-height: calc(100vh - 115px);
}

@include media-breakpoint-up(md) {
    .body-container {
        margin-top: 8.5rem;
    }
}

.ibancar-election {
    margin: 1rem 2rem 0rem 2rem;

    p {
        color: #004680;
        text-align: center;
        font-family: 'Montserrat-Bold';
        font-size: 1.3rem;
        font-style: normal;
        line-height: normal;
        margin: 1.5rem 0rem;
    }
}

.text-container {
    width: 80%;
    margin-left: auto;
    margin-right: auto;
    font-size: 20px;
    text-align: justify;
}

.regular-text {
    font-family: "Montserrat";
    font-size: 20px;
    line-height: 30px;
}

.title {
    font-size: 25px;
    line-height: 30px;
}

.bold-text {
    font-family: "Montserrat-Bold";
}

.text-orange {
    color: $orange;
}

.text-blue {
    color: $blue;
}

.text-black {
    color: $black;
}

.text-underline {
    text-decoration: underline;
}


.btn-solicitar {
    a {
        &:hover {
            text-decoration: none;
        }
    }
}

// Image
.image {
    bottom: 0;
    right: 0;
    width: 30%;
    z-index: -1;
}

@include media-breakpoint-down(md) {
    .text-container {
        width: 90%;
    }

    .regular-text {
        line-height: 1.5;
    }

    ul {
        padding-inline-start: 20px;
    }

    ol {
        padding-inline-start: 20px;
    }
}

@include media-breakpoint-between(md, lg) {
    .regular-text {
        font-size: 16px;
    }

    .title {
        font-size: 28px;
        line-height: 35px;
    }

    .subtitle {
        font-size: 16px;
    }
}

@include media-breakpoint-down(sm) {
    .title {
        font-size: 20px;
        line-height: 25px;
    }

    .subtitle {
        font-size: 16px;
        line-height: 20px;
    }

    .regular-text {
        font-size: 16px !important;
    }

    .section-container {
        margin-top: 1.5rem;
    }
}
</style>